import * as Icons from "@material-ui/icons";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import React from "react";
import {
  ArrayInput,
  BooleanInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  downloadCSV,
  Edit,
  ExportButton,
  Filter,
  FormTab,
  FunctionField,
  ImageField,
  List,
  NumberInput,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  SelectInput,
  ShowController,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  UrlField,
  ArrayField
} from "react-admin";
import SchoolEditActions from "./../actions/schoolEditActions";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";

const sourceOfRegistrationOptions = [
  { id: "obby", name: "Obby" },
  { id: "baluu", name: "Baluu" }
];

const SchoolStatus = {
  ACCOUNTCREATED: "accountcreated",
  WAITINGFORAPPROVAL: "waitingforapproval",
  ENABLED: "enabled",
  ARCHIVED: "archived",
  REMOVED: "removed"
};

const exporter = schools => {
  let defaultObjAccountManagement = {
    totalGMV: 0,
    totalBookings: 0,
    last3monthsGMV: 0,
    totalClasses: 0,
    totalLiveClasses: 0,
    totalLiveSessions: 0,
    daysToInactivity: -1,
    widgetMSPEnabled: false,
    totalReviews: 0,
    totalBookingsOfWidget: 0,
    haveInsurance: false,
    wantInsurance: false,
    giftCardEnabled: false,
    flexitimeEnabled: false
  };
  const schoolsForExport = schools.map(school => {
    let schoolForExport = {};
    if (typeof school.accountManagment == "undefined") {
      school.accountManagment = defaultObjAccountManagement;
    }
    schoolForExport.id = school.id;
    schoolForExport.name = school.name;
    schoolForExport.email = school.email;
    schoolForExport.ca = school.ca;
    schoolForExport.bookingCommission = school.bookingCommission;
    schoolForExport.rating = school.rating;
    schoolForExport.createdAt = school.createdAt;
    schoolForExport.totalGMV = school.accountManagment.totalGMV;
    schoolForExport.totalBookings = school.accountManagment.totalBookings;
    schoolForExport.last3monthsGMV = school.accountManagment.last3monthsGMV;
    schoolForExport.totalClasses = school.accountManagment.totalClasses;
    schoolForExport.totalLiveClasses = school.accountManagment.totalLiveClasses;
    schoolForExport.totalLiveSessions =
      school.accountManagment.totalLiveSessions;
    schoolForExport.daysToInactivity = school.accountManagment.daysToInactivity;
    schoolForExport.widgetMSPEnabled = school.accountManagment.widgetMSPEnabled;
    schoolForExport.totalReviews = school.accountManagment.totalReviews;
    schoolForExport.totalBookingsOfWidget =
      school.accountManagment.totalBookingsOfWidget;
    schoolForExport.haveInsurance = school.accountManagment.haveInsurance;
    schoolForExport.wantInsurance = school.accountManagment.wantInsurance;
    schoolForExport.giftCardEnabled = school.accountManagment.giftCardEnabled;
    schoolForExport.flexitimeEnabled = school.accountManagment.flexitimeEnabled;
    return schoolForExport;
  });
  const csv = convertToCSV({
    data: schoolsForExport,
    fields: [
      "id",
      "name",
      "email",
      "ca",
      "bookingCommission",
      "rating",
      "createdAt",
      "totalGMV",
      "totalBookings",
      "last3monthsGMV",
      "totalClasses",
      "totalLiveClasses",
      "totalLiveSessions",
      "daysToInactivity",
      "widgetMSPEnabled",
      "totalReviews",
      "totalBookingsOfWidget",
      "haveInsurance",
      "wantInsurance",
      "giftCardEnabled",
      "flexitimeEnabled"
    ] // order fields in the export
  });
  downloadCSV(csv, "schools"); // download as 'schools.csv` file
};

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);

const SchoolFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="qID" />
    <TextInput label="Search by name" source="qName" alwaysOn />
    <TextInput label="Search by school email" source="qEmail" />
    <SelectInput
      label="Searcy By School Status"
      source="qStatus"
      choices={[
        {
          id: SchoolStatus.ACCOUNTCREATED,
          name: SchoolStatus.ACCOUNTCREATED
        },
        {
          id: SchoolStatus.WAITINGFORAPPROVAL,
          name: SchoolStatus.WAITINGFORAPPROVAL
        },
        { id: SchoolStatus.ENABLED, name: SchoolStatus.ENABLED },
        { id: SchoolStatus.ARCHIVED, name: SchoolStatus.ARCHIVED },
        { id: SchoolStatus.REMOVED, name: SchoolStatus.REMOVED }
      ]}
    />
    <SelectInput
      label="Searcy By Account type"
      source="qPlanID"
      choices={[
        {
          id: "ePwQWNDEemSas5d5K",
          name: "Enterprise"
        },
        {
          id: "6c25bmFXdAyCJG2sY",
          name: "Growth"
        },
        {
          id: "jBndSmWp4pTnShE5n",
          name: "Standard"
        },
        {
          id: "6QBhPfCCPESSKz6pn",
          name: "Lite"
        },
        {
          id: "DdTieaq3XHBPSaWuz",
          name: "Free"
        },
        {
          id: "egZnPaYnqevKkjGhC",
          name: "Legacy Widget"
        },
        {
          id: "aNr28vAiukHBMKLkW",
          name: "Legacy Marketplace"
        }
      ]}
    />
    <SelectInput
      label="Filter by Source of Registration"
      source="qSourceOfRegistration"
      choices={sourceOfRegistrationOptions}
    />
  </Filter>
);

export const SchoolList = props => (
  <List
    {...props}
    filters={<SchoolFilter />}
    sort={{ field: "createdAt", order: "DESC" }}
    actions={<PostActions />}
    exporter={exporter}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="status" />
      <TextField label="Stripe Account Type" source="stripeConnect.type" />
      <FunctionField
        source="sourceOfRegistration"
        render={item =>
          item.sourceOfRegistration === "baluu" ? "Baluu" : "Obby"
        }
      />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);

export const SchoolCreate = props => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="summary">
        <BooleanInput label="Commercial agreement" source="ca" />
        <BooleanInput
          label="Hide from Google (note: If setup to true it will remove associated to course from algolia but the inverse is not true)"
          source="hidden"
        />
        <TextInput label="Name" source="name" />
        <TextInput disabled label="Slug" source="slug" />
        <TextInput
          multiline
          fullWidth
          label="Description"
          source="description"
        />
        <TextInput label="Email" source="email" type="email" />
        <TextInput label="Website" source="website" />
        <TextInput label="Phone" source="phone" />
        <TextInput label="VAT Number" source="vatNumber" />
        <TextInput disabled label="Hash" source="emailHash" />
        <BooleanInput label="Hide course" source="hideCourses" />
        <BooleanInput label="Msp" source="msp" />
        <DateInput label="Created at" source="createdAt" />
        <DateInput label="Updated at" source="updatedAt" />
        <DateInput label="Trial End Date" source="trialDetails.trialEnds" />
        <TextInput source="groupName" />
        <TextInput label="Partner original URL" source="website" />
        <TextInput
          label="Account manager"
          source="accountManager"
          type="email"
        />
        <SelectInput
          label="Source of Registration"
          source="sourceOfRegistration"
          choices={sourceOfRegistrationOptions}
        />
      </FormTab>
      <FormTab label="Commission">
        <NumberInput
          label="Marketplace Commission"
          source="bookingCommission"
        />
        <NumberInput label="Widget Commission" source="widgetCommission" />
        <NumberInput label="Online Commission" source="onlineCommission" />
        <NumberInput label="Rating" source="rating" />
        <NumberInput label="Response rate" source="responseRate" />
        <TextInput label="Response time" source="responseTime" />
      </FormTab>

      <FormTab label="Images">
        <ArrayInput source="images">
          <SimpleFormIterator>
            <TextInput source="url" />
            <TextInput source="alt" />
            <TextInput source="title" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export const SchoolEdit = props => (
  <ShowController {...props}>
    {({ record = {}, ...controllerProps }) => {
      return (
        <Edit {...props} actions={<SchoolEditActions />}>
          <TabbedForm>
            <FormTab label="summary">
              <TextInput disabled label="Id" source="id" />
              <SelectInput
                label="Status"
                source="status"
                choices={[
                  {
                    id: SchoolStatus.ACCOUNTCREATED,
                    name: SchoolStatus.ACCOUNTCREATED
                  },
                  {
                    id: SchoolStatus.WAITINGFORAPPROVAL,
                    name: SchoolStatus.WAITINGFORAPPROVAL
                  },
                  { id: SchoolStatus.ENABLED, name: SchoolStatus.ENABLED },
                  { id: SchoolStatus.ARCHIVED, name: SchoolStatus.ARCHIVED },
                  { id: SchoolStatus.REMOVED, name: SchoolStatus.REMOVED }
                ]}
              />
              <SelectInput
                label="Preferred Currency"
                source="preferredCurrency"
                choices={[
                  { id: "GBP", name: "GBP" },
                  { id: "EUR", name: "EUR" },
                  { id: "USD", name: "USD" },
                  { id: "AUD", name: "AUD" },
                  { id: "NZD", name: "NZD" },
                  { id: "CAD", name: "CAD" }
                ]}
              />
              <NumberInput label="Custom Commission" source="customComission" />
              <BooleanInput label="Commercial agreement" source="ca" />
              <BooleanInput
                label="Hide from Google (note: If setup to true it will remove associated courses from algolia but the inverse is not true)"
                source="hidden"
              />
              <BooleanInput
                label="Hide course (note: If setup to true it will remove associated courses from algolia but the inverse is not true)"
                source="hideCourses"
              />
              <BooleanInput
                label="Exclude from sales (note: It will remove the associated courses from algolia too)"
                source="excludeFromSales"
              />
              <TextInput label="Name" source="name" />
              <TextInput disabled label="Slug" source="slug" />
              <BooleanInput
                label="Is Widget enabled?"
                source="isWidgetEnabled"
              />
              <TextInput
                multiline
                fullWidth
                label="Description"
                source="description"
              />
              <TextInput label="Email" source="email" type="email" />
              <TextInput label="Website" source="website" />
              <TextInput label="Phone" source="phone" />
              <TextInput disabled label="Hash" source="emailHash" />
              <BooleanInput label="Msp" source="msp" />
              <DateInput label="Created at" source="createdAt" />
              <DateInput label="Updated at" source="updatedAt" />
              <DateInput
                label="Trial End Date"
                source="trialDetails.trialEnds"
              />
              <TextInput source="groupName" />
              <TextInput label="Partner original URL" source="website" />
              <ReferenceInput
                label="Account Type"
                source="planId"
                reference="plans"
                sort={{ field: "order", order: "ASC" }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <SelectInput
                label="Source of Registration"
                source="sourceOfRegistration"
                choices={sourceOfRegistrationOptions}
              />
              <SelectInput
                label="Preferred App"
                source="preferredApp"
                choices={sourceOfRegistrationOptions}
              />
              <TextField
                source="preferredCurrency"
                label="Preferred Currency"
              />
              <TextField source="timezone" label="Timezone" />
            </FormTab>
            <FormTab label="Commission">
              <SelectInput
                label="Payment Schedule"
                source="paymentSchedule"
                choices={[
                  { id: "weekly", name: "weekly" },
                  { id: "monthly", name: "monthly" }
                ]}
              />
              <NumberInput
                label="Marketplace Commission"
                source="bookingCommission"
              />
              <NumberInput
                label="Widget Commission"
                source="widgetCommission"
              />
              <NumberInput label="Rating" source="rating" />
              <NumberInput label="Response rate" source="responseRate" />
              <TextInput label="Response time" source="responseTime" />
            </FormTab>
            <FormTab label="Images">
              <ImageField source="images" src="url" title="title" />
            </FormTab>
            <FormTab label="Subscription&Connect">
              <h3>Subscription Data</h3>
              <TextField label="Subscription ID" source="subscription" />
              <TextField
                label="Subscription Status"
                source="subscriptionStatus"
              />
              <BooleanInput
                label="Is Subscription marked as unpaid?"
                source="subscriptionIsUnpaid"
              />
              <h3>Obby payment validation</h3>
              <BooleanInput
                label="Is Stripe Connect Validated By Obby"
                source="obbyValidate"
              />
              <h3>Beta program</h3>
              <BooleanInput
                label="Is DirectCharges Beta program Enabled ?"
                source="directChargesBetaProgram"
              />
              <BooleanInput source="directChargesBetaProgramSwapped" />
              <h3>Current Connect Data</h3>
              <BooleanInput
                label="Is Stripe Connect Enabled"
                source="stripeConnect.enabled"
              />
              <TextField
                label="Stripe Account ID"
                source="stripeConnect.accountID"
              />
              <TextField
                label="Stripe Account Type"
                source="stripeConnect.type"
              />
              <BooleanInput source="stripeConnect.directChargesEnabled" />
              <BooleanInput source="stripeConnect.stripeHostedPage" />
              <DateField
                source="stripeConnect.stripeFormatedData.stripeAccountCreatedAt"
                label="Start Date"
              />
              <DateField
                source="stripeConnect.stripeDateUpdateAt"
                label="Stripe data update at"
              />
              <h3>Alternative Connect Data</h3>
              <BooleanInput
                label="Is Stripe Connect Enabled"
                source="stripeConnectAlt.enabled"
              />
              <TextField
                label="Stripe Account ID"
                source="stripeConnectAlt.accountID"
              />
              <TextField
                label="Stripe Account Type"
                source="stripeConnectAlt.type"
              />
              <BooleanInput source="stripeConnectAlt.directChargesEnabled" />
              <BooleanInput source="stripeConnectAlt.stripeHostedPage" />
              <DateField
                source="stripeConnectAlt.stripeFormatedData.stripeAccountCreatedAt"
                label="Start Date"
              />
              <DateField
                source="stripeConnectAlt.stripeDateUpdateAt"
                label="Stripe data update at"
              />
              <h3>OLD Connect Data</h3>
              <BooleanInput
                label="Is Stripe Connect Enabled"
                source="stripeConnectOld.enabled"
              />
              <TextField
                label="Stripe Account ID"
                source="stripeConnectOld.accountID"
              />
              <TextField
                label="Stripe Account Type"
                source="stripeConnectOld.type"
              />
              <BooleanInput source="stripeConnectOld.directChargesEnabled" />
              <BooleanInput source="stripeConnectOld.stripeHostedPage" />
              <DateField
                source="stripeConnectOld.stripeFormatedData.stripeAccountCreatedAt"
                label="Start Date"
              />
              <DateField
                source="stripeConnectOld.stripeDateUpdateAt"
                label="Stripe data update at"
              />
            </FormTab>
            <FormTab label="Bank Details">
              <TextInput label="Bank Business Name" source="bankBusinessName" />
              <TextInput label="Bank Name" source="bankName" />
              <TextInput label="Bank Sort Code" source="bankSortCode" />
              <TextInput
                label="Bank Account Number"
                source="bankAccountNumber"
              />
            </FormTab>
            <FormTab label="Account Management">
              <BooleanInput label="Active" source="activeTeacher" />
              <DateInput
                label="Activation Date for Teacher"
                source="activationDateTeacer"
              />
              <DateInput
                label="Wdiget ActivationDate for Teacher"
                source="widgetActivationDate"
              />
              <TextInput
                label="Account manager"
                source="accountManager"
                type="email"
              />
              <NumberInput
                label="Total GMV (pounds)"
                source="accountManagment.totalGMV"
              />
              <NumberInput
                label="Total GMV last 3 months (pounds)"
                source="accountManagment.last3monthsGMV"
              />
              <NumberInput
                label="How many days until Inactivity?"
                source="accountManagment.daysToInactivity"
              />
              <NumberInput
                label="Total classes"
                source="accountManagment.totalClasses"
              />
              <NumberInput
                label="Total Live classes"
                source="accountManagment.totalLiveClasses"
              />
              <NumberInput
                label="Total Live sessions"
                source="accountManagment.totalLiveSessions"
              />
              <NumberInput
                label="Total Bookings"
                source="accountManagment.totalBookings"
              />
              <NumberInput
                label="Total Bookings made on Widget"
                source="accountManagment.totalBookingsOfWidget"
              />
              <NumberInput
                label="Total Reviews"
                source="accountManagment.totalReviews"
              />
              <BooleanInput
                label="Is Widget enabled?"
                source="accountManagment.widgetMSPEnabled"
              />
              <BooleanInput
                label="Is Giftcard from widget enabled?"
                source="accountManagment.giftCardEnabled"
              />
              <BooleanInput
                label="Is insurance enabled?"
                source="accountManagment.insuranceEnabled"
              />
              <BooleanInput
                label="Override widget status on dashboard home page"
                source="widgetStatus.statusIsOverridden"
              />
              <BooleanInput
                label="Widget status override state (the above must be turned on for this value to be used)"
                source="widgetStatus.showAsActive"
              />
            </FormTab>
            <FormTab label="Payout settings">
              <SelectInput
                label="interval"
                source="payoutScheduleSettings.interval"
                choices={[
                  { id: "daily", name: "daily" },
                  { id: "weekly", name: "weekly" },
                  { id: "monthly", name: "monthly" }
                ]}
              />
              <NumberInput
                source="payoutScheduleSettings.monthly_anchor"
                label="monthly_anchor"
                min={1}
                max={31}
              />
              <SelectInput
                label="weekly_anchor"
                source="payoutScheduleSettings.weekly_anchor"
                choices={[
                  { id: null, name: "None" },
                  { id: "monday", name: "monday" },
                  { id: "tuesday", name: "tuesday" },
                  { id: "wednesday", name: "wednesday" },
                  { id: "thursday", name: "thursday" },
                  { id: "friday", name: "friday" },
                  { id: "saturday", name: "saturday" },
                  { id: "sunday", name: "sunday" }
                ]}
              />
              <div>
                Making changes here will affect school that are slected to this
                plan after the change. If we want to update the school of this
                plan prior to changes we need an button that trigger sync.
              </div>
            </FormTab>
            <FormTab label="Insurance">
              <SelectInput
                label="Has Insurance"
                source="haveInsurance"
                choices={[
                  { id: null, name: "" },
                  { id: true, name: "Yes" },
                  { id: false, name: "No" }
                ]}
              />
              {record.haveInsurance === false && (
                <SelectInput
                  label="Want Insurance"
                  source="wantInsurance"
                  choices={[
                    { id: null, name: "" },
                    { id: true, name: "Yes" }
                  ]}
                />
              )}
              {record.haveInsurance === false &&
                record.wantInsurance && [
                  <TextInput
                    key={0}
                    label="First Name"
                    source="insuranceDetails.firstName"
                  />,
                  <TextInput
                    key={1}
                    label="Last Name"
                    source="insuranceDetails.lastName"
                  />,
                  <TextInput
                    key={2}
                    label="Business Name"
                    source="insuranceDetails.businessName"
                  />,
                  <TextInput
                    key={3}
                    label="Business Trade"
                    source="insuranceDetails.businessTrade"
                  />,
                  <TextInput
                    key={4}
                    label="Address 1"
                    source="insuranceDetails.addresses[0]"
                  />,
                  <TextInput
                    key={5}
                    label="Address 2"
                    source="insuranceDetails.addresses[1]"
                  />,
                  <TextInput
                    key={6}
                    label="Town"
                    source="insuranceDetails.town"
                  />,
                  <TextInput
                    key={7}
                    label="City"
                    source="insuranceDetails.city"
                  />,
                  <TextInput
                    key={8}
                    label="Postcode"
                    source="insuranceDetails.postcode"
                  />,
                  <SelectInput
                    label="Payment plan"
                    source="insuranceDetails.paymentPlan"
                    choices={[
                      { id: "monthly", name: "Monthly £8 (+VAT)" },
                      { id: "annual", name: "Annual £70 (+VAT) 30% saving" }
                    ]}
                  />
                ]}
              {record.haveInsurance && [
                <TextInput
                  key={0}
                  label="Business Name"
                  source="insuranceDetails.businessName"
                />,
                <DateInput
                  key={1}
                  label="Start Date"
                  source="insuranceDetails.startDate"
                />,
                <NumberInput
                  key={2}
                  label="Premium Cost"
                  source="insuranceDetails.premiumCost"
                />,
                <UrlField
                  key={3}
                  label="Certificate"
                  source="insuranceDetails.certificate"
                />
              ]}
            </FormTab>

            <FormTab label="tutors">
              <ReferenceArrayField
                reference="users"
                source="tutors"
                label=""
                fullWidth
              >
                <Datagrid>
                  <ReferenceField label="Name" source="_id" reference="users">
                    <TextField source="fullName" />
                  </ReferenceField>
                  <TextField source="email" />
                  <FunctionField
                    label="Owner"
                    render={record => (
                      <span>
                        {record.roles.includes("owner") && (
                          <Icons.Check fontSize="small" />
                        )}
                      </span>
                    )}
                  />
                </Datagrid>
              </ReferenceArrayField>
            </FormTab>

            <FormTab label="Custom Domain">
              <SelectInput
                label="Preferred App"
                source="preferredApp"
                choices={sourceOfRegistrationOptions}
              />
              <hr />
              <BooleanInput
                label="Is custom domain enabled?"
                source="customDomain.isEnabled"
              />
              <BooleanInput
                label="User wants custom domain?"
                source="customDomain.usersWants"
              />
              <BooleanInput
                label="Custom domain is setup?"
                source="customDomain.isSetup"
              />
              <TextInput
                label="Custom domain"
                source="customDomain.userdomain"
              />
              <hr />
              <BooleanInput
                label="Is custom email domain enabled?"
                source="customEmailDomain.isEnabled"
              />
              <BooleanInput
                label="User wants email custom domain?"
                source="customEmailDomain.usersWants"
              />
              <BooleanInput
                label="Email custom domain is setup?"
                source="customEmailDomain.isSetup"
              />
              <TextInput
                label="Custom email domain"
                source="customEmailDomain.userdomain"
              />
            </FormTab>

            <FormTab label="Papertrail">
              <PapertrailReferenceTable />
            </FormTab>
          </TabbedForm>
        </Edit>
      );
    }}
  </ShowController>
);
