import { DashboardPermissionsLabels } from "../constants/DashboardPermissionsLabels";
import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  CardActions,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  ExportButton,
  Filter,
  FormDataConsumer,
  FormTab,
  ImageField,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceInput,
  RefreshButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";
import { PapertrailReferenceTable } from "../components/PapertrailReferenceTable";
import UserEditActions from "../actions/UserEditActions";

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={10000}
    />
    <RefreshButton />
  </CardActions>
);
const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="qID" />
    <TextInput label="Search by email" source="qEmail" alwaysOn />
    <TextInput label="Search by name" source="qName" />
    <TextInput label="Search by School ID" source="qSchoolID" />
    <TextInput label="Search by School name" source="qSchoolName" />
    <TextInput label="Search by School email" source="qSchoolEmail" />
  </Filter>
);

export const UserList = props => (
  <List
    {...props}
    filters={<UserFilter />}
    sort={{ field: "createdAt", order: "ASC" }}
    actions={<PostActions />}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="First Name" source="profile.firstName" />
      <TextField label="Last Name" source="profile.lastName" />
      <TextField label="Email" source="renderEmail" />
      <NumberField label="Credit" source="profile.credit" />
    </Datagrid>
  </List>
);

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Email" source="emails[0].address" />
      <TextInput label="First name" source="profile.firstName" />
      <TextInput label="Last name" source="profile.lastName" />
      <TextInput label="Password" source="password" />
      <TextInput label="Phone" source="profile.phone" />
      <SelectArrayInput
        label="Roles"
        source="roles"
        choices={[
          { id: "user", name: "User" },
          { id: "partner", name: "Partner" },
          { id: "owner", name: "Owner" },
          { id: "admin", name: "Admin" },
          { id: "staff", name: "Staff" },
          { id: "manager", name: "Manager" },
          { id: "restricted", name: "Restricted" }
        ]}
      />
      <ReferenceInput
        label="School"
        source="schoolId"
        reference="schools"
        perPage={25}
        filterToQuery={searchText => ({ qName: searchText })}
      >
        <AutocompleteInput
          optionText="name"
          allowEmpty={true}
          shouldRenderSuggestions={val => {
            return val.trim().length >= 2;
          }}
          highlightFirstSuggestion={false}
          inputValueMatcher={() => null}
          options={{
            fullWidth: true,
            suggestionsContainerProps: {
              disablePortal: false
            }
          }}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
var options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  timeZone: "Europe/London"
};
export const UserEdit = props => (
  <Edit {...props} actions={<UserEditActions />}>
    <TabbedForm>
      <FormTab label="User info">
        <TextInput disabled label="Id" source="id" />
        <SelectInput
          label="Status"
          source="status"
          choices={[
            { id: "waitingforapproval", name: "waitingforapproval" },
            { id: "approved", name: "approved" },
            { id: "newteacher", name: "newteacher" }
          ]}
        />
        <TextInput label="Email" source="emails[0].address" />
        <TextInput label="First name" source="profile.firstName" />
        <TextInput label="Last name" source="profile.lastName" />
        <TextInput label="Change Password" source="changePwd" />
        <BooleanInput
          label="Bypass the 2fa authenticaion?"
          source="byPass2FaAuth"
        />
        <TextInput label="Phone" source="profile.phone" />
        <TextInput label="Referral code" source="profile.referralCode" />
        <TextField label="Hash" source="emailHash" />
        <BooleanInput label="Newsletter" source="profile.newsletter" />
        <SelectArrayInput
          label="Roles"
          source="roles"
          choices={[
            { id: "user", name: "User" },
            { id: "partner", name: "Partner" },
            { id: "owner", name: "Owner" },
            { id: "admin", name: "Admin" },
            { id: "staff", name: "Staff" },
            { id: "manager", name: "Manager" },
            { id: "restricted", name: "Restricted" }
          ]}
        />
        <DateField label="Created Date" source="createdAt" locales="en-GB" />
        <DateField label="Updated Date" source="updatedAt" locales="en-GB" />
        <ImageField label="Profile photo" source="profile.imageUrl" />
      </FormTab>
      <FormTab label="Credit">
        <NumberInput label="Credit amount" source="profile.credit" />
        <BooleanInput
          label="School review credit applied"
          source="profile.appliedSchoolReviewCredit"
        />
        <BooleanInput
          label="Applied invitation credit"
          source="profile.appliedInvitationCredit"
        />
        <ArrayInput label="Invited history" source="profile.invitedHistory">
          <SimpleFormIterator>
            <TextInput label="Email" source="emailAddress" />
            <BooleanInput label="Existing User" source="existingUser" />
            <TextInput label="User ID" source="userId" />
            <DateField
              label="Created date"
              source="createdAt"
              locales="en-GB"
              options={options}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="School">
        <ReferenceInput
          label="School"
          source="schoolId"
          reference="schools"
          perPage={25}
          filterToQuery={searchText => ({ qName: searchText })}
        >
          <AutocompleteInput
            optionText="name"
            allowEmpty={true}
            shouldRenderSuggestions={val => {
              return val.trim().length >= 2;
            }}
            highlightFirstSuggestion={false}
            inputValueMatcher={() => null}
            options={{
              fullWidth: true,
              suggestionsContainerProps: {
                disablePortal: false
              }
            }}
          />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Permissions">
        <ArrayInput source="permissions" label="">
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource, ...props }) => (
                <BooleanInput
                  source={getSource("value")}
                  {...props}
                  label={
                    DashboardPermissionsLabels[scopedFormData.name] ||
                    scopedFormData.name
                  }
                />
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Favourites">
        <ReferenceArrayField
          label="Favourites"
          reference="courses"
          source="favourites"
        >
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
      </FormTab>

      <FormTab label="Papertrail">
        <PapertrailReferenceTable />
      </FormTab>
    </TabbedForm>
  </Edit>
);

// the react admin doesn't handle very well simple array (without key) due to redux form, so here its a custom way.
const FavouritesField = ({ record }) => (
  <ul>
    {record.favourites.map(item => (
      <li key={item}>{item}</li>
    ))}
  </ul>
);
FavouritesField.defaultProps = { addLabel: true };
